import React, { useState } from 'react';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [requests, setRequests] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://api.thiisis.com/links/anonymous', {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Origin': 'https://thiisis.com/'
      },
      body: input,
    });

    const data = await response.json();
    setOutput(data.hash);
    setRequests([...requests, { input, hash: data.hash }]);
  };

  return (
      <div className="bg-gray-100 min-h-screen">
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <div className="flex items-center">
              <img src="/logo.png" alt="Logo" className="h-12 w-auto mr-2"/>
            </div>
            <nav className="relative">
              <button className="text-gray-700 hover:text-gray-900 focus:outline-none focus:text-gray-900">
                About
              </button>
            </nav>
          </div>
        </header>
        <main className="flex flex-col items-center justify-center py-12">
          <form onSubmit={handleSubmit} className="w-3/5">
            <div className="mb-4">
              <label htmlFor="inputField" className="block text-sm font-medium text-gray-700">
                Enter URL to Shorten
              </label>
              <input
                  type="text"
                  id="inputField"
                  name="inputField"
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Type URL here..."
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
              />
            </div>
            <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Shorten
            </button>
          </form>
          <div className="w-3/5 mt-8">
            <h2 className="text-xl font-bold mb-4">Shortened URLs</h2>
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
              <tr>
                <th className="px-4 py-2 border-b">Input URL</th>
                <th className="px-4 py-2 border-b">Shortened Hash</th>
              </tr>
              </thead>
              <tbody>
              {requests.map((req, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2 border-b">{req.input}</td>
                    <td className="px-4 py-2 border-b">{req.hash}</td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
  );
}

export default App;
